import React from "react";
import { getScheduleContractDetails } from "../../action/contract/getScheduleContractDetails";
import { formatMonthDate } from "../../utils/const";
import Loader from "../loader/loader";
import {
  CreatePaymentIntent,
  ConfirmPaymentIntent
} from "../../action/card/paymentIntent";
import { getCardsDetails } from "../../action/cardDetails/cards";
import $ from 'jquery';
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import { Redirect, useHistory } from "react-router";
import { GTMUserIdAndLoginState } from "../../utils/GtmDatalayer";

export class WalletContract extends React.Component {
  constructor(props) {
  
    super(props);
    this.state = {
      value: 0,
      contractId: "",
      contractDataDetails: "",
      contractScheduleDetails: "",
      nextPaymentDetails: "",
      convertednextPaymentDetails: "",
      isLoading: false,
      paymentMethodId: "",
      paymentIntentId: "",
      paymentAmount: "",
      noContractMsg:'',
      redirect:false,
      hasActiveCards: []
    };
    this.child = React.createRef();
    this.goBack = this.goBack.bind(this);
    this.handleRangeChange = this.handleRangeChange.bind(this);
  }

  goBack(e) {
    analyticsClickEvent('wallet_back', '')
    this.props.history.push({pathname: "/wallet",state:{msg:this.child}});
  }
  handleRangeChange(event) {
    this.setState({ value: event.target.value });
  }

  async componentDidMount() {
    if(localStorage.getItem('forwardRoute') !== '' && localStorage.getItem('forwardRoute') !== null){
      localStorage.removeItem('forwardRoute');
    }
    this.initialLoad();

    // Push the customer id and loginState if available on this page to the dataLayer
    window.dataLayer = window.dataLayer || {};
    GTMUserIdAndLoginState();
  }

  initialLoad = async () => {
    let contractId = this.props.location.state;
    this.setState({ contractId: contractId.contract_id })
    this.setState({ isLoading: true });
    let specificContractDetails = await getScheduleContractDetails(contractId);
    console.log(specificContractDetails);
  
    this.setState({ isLoading: false });
    let contractDetails = [];
    let contractSchedule = [];
    let nextPayment = [];
    localStorage.setItem('getScheduleContractDetails','');
   

    if(specificContractDetails.status === 400){
      if(Array.isArray(specificContractDetails.data.data.contract_schedule) && specificContractDetails.data.data.contract_schedule.length===0){
        localStorage.setItem('getScheduleContractDetails',specificContractDetails.data.message)
      }
    }
    if (specificContractDetails.status === 200) {
      this.setState({ isLoading: false });
      let constractSheduleLength = specificContractDetails.data.data.contract_schedule.length;
      let paidStatusCount = 0;
      specificContractDetails.data.data.contract_details.map((response) => {
        contractDetails.push(response);
      });
      specificContractDetails.data.data.contract_schedule.map((schedule) => {
        if (schedule.status__c === "Paid") {
          paidStatusCount++;
        }
        let datadetails = {
          paymentStatus: schedule.status__c,
          paymentAmount: schedule.due_amount,
          paymentDate: formatMonthDate(schedule.due_date),
          contractpayment_schedule_id: schedule.contractpayment_schedule_id,
        };
        contractSchedule.push(datadetails);
        let nextpaydata = {
          paymentAmount: schedule.due_amount,
          payDate: formatMonthDate(schedule.due_date),
          contractpayment_schedule_id: schedule.contractpayment_schedule_id,
          paymentStatus: schedule.status__c
        };

        nextPayment.push(nextpaydata);
      });
      this.setState({ isLoading: false });
      let contractbal = (paidStatusCount / constractSheduleLength) * 100;
      // if(contractDetails[0].total_loan_balance_amount !=='' && contractDetails[0].total_loan_amount !== ''){
      // if(contractDetails[0].total_loan_balance_amount <= contractDetails[0].total_loan_amount){
      //   console.log(contractDetails[0].total_loan_balance_amount.split(',').join(""));
      // contractbal = 100 - ((contractDetails[0].total_loan_balance_amount.split(',').join("") / contractDetails[0].total_loan_amount.split(',').join("")) * 100);
      // }
      //}

      let convertednextPaymentDetailData = this.sortData(nextPayment, { paymentStatus: 'asc' });
      await this.setState({
        contractDataDetails: contractDetails,
        contractScheduleDetails: contractSchedule,
        nextPaymentDetails: nextPayment,
        convertednextPaymentDetails: convertednextPaymentDetailData,
        value: Math.round(contractbal)
      });
      $("#myRange").css({ 'backgroundImage': `linear-gradient(90deg, #ff6200 ${this.state.value}%, transparent ${this.state.value}%)` })
    } else if (specificContractDetails.status === 500) {
      this.setState({ isLoading: false });
      this.goBack();
    }

    if (specificContractDetails.status === 400) {
      this.goBack();
    }

    setTimeout(async() => {
      this.userCardDetails();
    }, 300);

  };

  userCardDetails = async() => {
    const userCardDetails = await getCardsDetails({
      email: localStorage.getItem("email"),
    });

    if(userCardDetails !== undefined){
    if (userCardDetails.data.statusCode === 200) {
      let hasActiveCardsArray = [];
      userCardDetails.data.data.map((response) => {
          let dataObject = {
            cardId: response.hummuk_customer_card_detail_id,
            cardNumber: response.hummuk_customer_card_detail_card_number__c,
            cardExpiryMonth: response.hummuk_customer_card_detail_exp_month,
            cardExpiryYear: response.hummuk_customer_card_detail_exp_year,
            PaymentMethodId: response.hummuk_customer_card_detail_payment_method_id__c,
            cardHoldName: response.hummuk_customer_card_detail_card_holder_name__c,
            cardStatus: response.hummuk_customer_card_detail_primary_card__c,
            cardType:response.hummuk_customer_card_detail_card_type__c.toLowerCase()
          };
        hasActiveCardsArray.push(dataObject);
      });

      this.setState({ hasActiveCards: hasActiveCardsArray }, () => {

        for(const [i,value] of this.state.hasActiveCards.entries()){
          if(value.cardStatus === true){
            console.log(value.cardStatus, value.cardNumber, i);
            this.setState({primaryCardNumber: value.cardNumber});
          }
        }
      });
    }

  }
  }

  redirectToAddCardTab = (event) => {
    event.preventDefault();
    window.$("#nextPay").modal("hide")
    localStorage.setItem('forwardRoute', '/wallet');
    this.props.history.push('/wallet');
  }

  payNow = async (contractScheduleID) => {
    analyticsClickEvent('paynow', '')
    this.setState({ contractScheduleID: contractScheduleID })

    this.setState({
      paymentAmount: this.state.nextPaymentDetails[0].paymentAmount,
    });
    window.$("#nextPay").modal("show");
  };

  paymentConfirm = async () => {

    const objs = {
      email: localStorage.getItem("email"),
      amount: parseFloat(this.state.nextPaymentDetails[0].paymentAmount),
      contract_id: this.state.contractId,
      contractpayment_schedule_id: this.state.contractScheduleID
    };
    analyticsClickEvent('paynow_confirm', this.state.contractScheduleID)
    const results = await CreatePaymentIntent(objs);
    if (results) {
      if (results.data.statusCode) {
        this.setState({ paymentSuccessMessage: results.data.message });
        window.$("#nextPay").modal("hide");
      } else {
        return false;
      }
    }

    setTimeout(() => {
      window.$("#nextPaySuccess").modal("show");
    }, 1000);

    setTimeout(() => {
      window.$("#nextPaySuccess").modal("hide");
    }, 4000);

    setTimeout(() => {
      console.log("yes");
      this.initialLoad();
    }, 3000);
  };


  sortData = (data, orderBy) => {
    if (Array.isArray(data) && data.length != 0) {
      orderBy = Array.isArray(orderBy) ? orderBy : [orderBy];
      return data.sort((a, b) => {
        for (let i = 0, size = orderBy.length; i < size; i++) {
          const key = Object.keys(orderBy[i])[0],
            o = orderBy[i][key],
            valueA = a[key],
            valueB = b[key];
          if (!(valueA || valueB)) {
            console.error("the objects from the data passed does not have the key '" + key + "' passed on sort!");
            return [];
          }
          if (+valueA === +valueA) {
            return o.toLowerCase() === 'desc' ? valueB - valueA : valueA - valueB;
          } else {
            if (valueA.localeCompare(valueB) > 0) {
              return o.toLowerCase() === 'desc' ? -1 : 1;
            } else if (valueA.localeCompare(valueB) < 0) {
              return o.toLowerCase() === 'desc' ? 1 : -1;
            }
          }
        }
      });
    }
  }


  render() {
    if (this.state.redirect) {
      return <Redirect  to={{pathname: '/wallet',state:{noContractMsg:'msg'}}}/>
  }
    return (
      <div className="layout-fixed page-layout">
        <div className="wrapper">
          <Loader loaderStatus={this.state.isLoading} />
          <section className="main">
            <div className="row">
              <div className="col-12">
                <div className="wallet---itemQr">
                  <div className="container">
                    <div className="wallet-container custom-wallet-banner-ses">
                      <div className="lr-item--hero">
                        <div className="hero-left">
                          {this.state.contractDataDetails !== "" && (
                            <div className="hero-left-top">
                              <div className="col-lg-4 col-md-6 user-info">
                                <div className="d-flex align-items-center wallet-top-item">
                                  <div className="user-image-area contract-details-profile">
                                    <img
                                      src={
                                        this.state.contractDataDetails[0]
                                          .account_cover
                                      }
                                      alt={
                                        this.state.contractDataDetails[0]
                                          .account_name
                                      }
                                      className="img-fluid"
                                    />
                                  </div>
                                  <div className="user--detailQwe detail-contract-grt">
                                    <h2>
                                      {
                                        this.state.contractDataDetails[0]
                                          .account_name
                                      }
                                    </h2>
                                    <p>
                                      Start Date:{" "}
                                      {
                                        this.state.contractDataDetails[0]
                                          .contract_start_date
                                      }
                                    </p>
                                    <p>
                                      Completion Date:{" "}
                                      {
                                        this.state.contractDataDetails[0]
                                          .contract_end_date
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-8 col-md-6 pr-5 user-expense wallet-expense-item">
                                <div className="total-to-spend">
                                  <h6>Total Financed</h6>
                                  <h2>
                                    £
                                    {
                                      this.state.contractDataDetails[0]
                                        .total_loan_amount
                                    }
                                  </h2>
                                </div>
                                <div className="next-payment">
                                  <h6>Total Remaining</h6>
                                  <h2 className="wallet-font-style">
                                    £
                                    {
                                      this.state.contractDataDetails[0]
                                        .total_loan_balance_amount
                                    }
                                  </h2>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="back-btn">
                      <button
                        className="go-back-btn"
                        type="button"
                        onClick={(e) => this.goBack(e)}
                      >
                        Go Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="contract--item">
            <div className="container">
              <div className="payment-schedule">
                <h2>Payment Schedule</h2>
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="payment---Eqf">
                      <div className="d-flex patment-paysec">
                        <div className="left-secQes">
                          {this.state.convertednextPaymentDetails &&
                            <button
                              className={`paynow-btn ${this.state.convertednextPaymentDetails[0].paymentStatus === 'Awaiting' || this.state.convertednextPaymentDetails[0].paymentStatus === 'Paid' ? 'paynow-disable' : ''}`}
                              type="button"
                              onClick={() => {
                                this.payNow(this.state.convertednextPaymentDetails[0].contractpayment_schedule_id);
                              }}
                            >
                              {this.state.convertednextPaymentDetails[0].paymentStatus === 'Awaiting' &&
                                'Processing'
                              }
                              {this.state.convertednextPaymentDetails[0].paymentStatus === 'Outstanding' &&
                                'Pay now'
                              }
                              {this.state.convertednextPaymentDetails[0].paymentStatus === 'Paid' &&
                                'Paid'
                              }

                            </button>
                          }
                        </div>
                        <div className="rate-sec">
                          <p>
                            £
                            {this.state.nextPaymentDetails
                              ? this.state.nextPaymentDetails[0].paymentAmount
                              : ""}
                          </p>
                        </div>
                        <div className="date-sec">
                          {this.state.nextPaymentDetails.length !== 0 && (
                            <div className="date-pay-detail">
                              <p>
                                {this.state.nextPaymentDetails[0].payDate[1]}
                              </p>
                              <h3>
                                {this.state.nextPaymentDetails[0].payDate[0]}
                              </h3>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* right now hide manual payment */}
                      {/* <h5>Would you like to pay some extra off right now?</h5>
                                <button className="manual-pay-btn" type="button" data-toggle="modal" data-target="#manualPayclose">Manual Payment</button> */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="payment---Eqf table--item">
                      <table className="table">
                        <tbody>
                          {this.state.contractScheduleDetails !== "" &&
                            this.state.contractScheduleDetails.map(
                              (details) => (
                                <tr key={details.paymentStatus}>
                                  {details.paymentStatus === 'Awaiting' &&
                                    <td className="paid">
                                      Processing
                                    </td>
                                  }
                                  {details.paymentStatus === 'Outstanding' &&
                                    <td className="paid">
                                      Unpaid
                                    </td>
                                  }
                                  {details.paymentStatus === 'Paid' &&
                                    <td className="paid">
                                      Paid
                                    </td>
                                  }
                                  <td className="paid-amount">
                                    £{details.paymentAmount}
                                  </td>
                                  <td className="paid--date">
                                    <div className="date-pay-detail">
                                      <p>{details.paymentDate[1]}</p>
                                      <h3>{details.paymentDate[0]}</h3>
                                    </div>
                                  </td>
                                </tr>
                              )
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-12">
                    <div className="progress-section">
                      <h3>My Progress</h3>
                      <div className="slidecontainer">
                        <input
                          className="progress--slider paynow-disable"
                          id="myRange"
                          type="range"
                          min="0"
                          max="100"
                          value={this.state.value}
                          onChange={this.handleRangeChange}
                          step="1"
                        />
                        <p>
                          <span id="demo"></span>
                          {this.state.value} %
                        </p>
                        <div className="humm--logo">
                          <img
                            src="images/progress-logo.svg"
                            alt="logo"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="Contract--Details">
                    <h3>Contract Details</h3>
                </div> */}
            </div>
          </section>
        </div>

        <div
          className="modal fade"
          id="manualPay"
          tabindex="-1"
          role="dialog"
          aria-labelledby="manualPayTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content">
                  <h2>Manual Payment</h2>
                  <p>
                    Please confirm that you wish to pay the below amount to
                    which will be deducted from your card ending ***49
                  </p>
                </div>
                <form>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="£0.00"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#manualPayNext"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="manualPayNext"
          tabindex="-1"
          role="dialog"
          aria-labelledby="manualPayNextTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content">
                  <h2>Manual Payment</h2>
                  <p>
                    Please confirm that you wish to pay the below amount to
                    which will be deducted from your card ending ***49
                  </p>
                </div>
                <form>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="£42.25"
                    />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#manualPayclose"
                >
                  Confrim
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="manualPayclose"
          tabindex="-1"
          role="dialog"
          aria-labelledby="manualPaycloseTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content mb-3">
                  <h2>Manual Payment</h2>
                  <p>
                    Thank you! Your payment has been confirmed and the amount
                    deducted from your balance.{" "}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* ther is no contract model */}
        <div
          className="modal fade"
          id="noContractModel"
          tabindex="-1"
          role="dialog"
          aria-labelledby="noContractModelTitle"
          aria-hidden="true"
          visible="true"
          ata-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content mb-3">
                  {/* <h2>Message</h2> */}
                  <p>
                    {this.state.noContractMsg}
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>

        {/*Next Payment Modal */}

        <div
          className="modal fade"
          id="nextPay"
          tabindex="-1"
          role="dialog"
          aria-labelledby="nextPaycloseTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content mb-3">
                  <h2>Confirm Payment</h2>
                  <p className="mb-0">
                    Please confirm your payment £{this.state.paymentAmount}{" "}
                  </p>
                  <p className="mt-2 nextPay--card-number-text">from your primary card ending in {this.state.primaryCardNumber}</p>
                </div>
              </div>
              <div className="modal-footer nextPay-popup">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    this.paymentConfirm();
                  }}
                >
                  Confirm
                </button>
              </div>
              <div className="text-center">
                <a href="#" onClick={this.redirectToAddCardTab} className="nextPay--change-primary-card">Change Primary Card</a>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="nextPaySuccess"
          tabindex="-1"
          role="dialog"
          aria-labelledby="nextPaySuccesscloseTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="logo">
                  <img
                    src="images/modal-logo.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </div>
                <div className="tittle-content mb-3">
                  <h2>Payment Status</h2>
                  <p>{this.state.paymentSuccessMessage}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WalletContract;
