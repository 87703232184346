import React from 'react';
import { connect } from 'react-redux';
import { analyticsClickEvent } from '../../../action/firebaseAnalytics/firebaseAnalytics';

export class CheckBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = { errors: {},stages:{},input: {},showValidation:this.props.showAppValidations  }
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.handleValidations()
        let questionValues={};
        questionValues['field_api_name']=this.props.field_api_name;
        questionValues['priority']=this.props.priority;
        questionValues['stage']=this.props.stage;
        if(this.props.field_api_validations.length!=0){
          questionValues['validation']='yes';
        }else{
          questionValues['validation']='no';
        }
        this.props.questionSave(questionValues);

        if(this.props.field_api_name === 'flx_DocumentAgree__c') {
          this.props.saveDocumentAgreeLabel(this.props.field_label)
        }

        if(this.props.field_api_name === 'flx_Agree__c') {
          this.props.saveAgreeLabel(this.props.field_label)
        }

        if(this.props.field_api_name === 'flx_Contact_Humm_agree__c') {
          this.props.saveContactAgreeLabel(this.props.field_label)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.showAppValidations !== this.state.showValidation) {
          if(this.state.showValidation!=='yes'){
           this.setState({ showValidation:nextProps.showAppValidations })
          }
          
        }
       }
        

    handleChange(event) {
      console.log(event);
      console.log(this.props.flxFieldApiName__c)
      const item = event.target.name;
       const isChecked = event.target.checked; 
      
       if(item === 'flx_DocumentAgree__c'){
        analyticsClickEvent('checkbox',this.props.field_api_name)
         if(isChecked === true){
          //  analyticsClickEvent(this.props.field_api_name+'_checked','')
         }else{
          //  analyticsClickEvent(this.props.field_api_name+'_unChecked','')
         }

       }
       
       if(item === 'flx_Agree__c'){
        analyticsClickEvent('checkbox',this.props.field_api_name)
        if(isChecked === true){
          //  analyticsClickEvent(this.props.field_api_name+'_checked','')
         }else{
          //  analyticsClickEvent(this.props.field_api_name+'_unChecked','')
         }
       }
        this.setState({showValidation:'yes'});
        let input = this.state.input;
        let inputValues={};
        input[event.target.name] = event.target.checked;
        this.setState({
          input
        });
        inputValues[event.target.name] = event.target.checked;
        this.props.saveApplicationDatas(inputValues);
        this.handleValidations()
      }

      handleValidations=()=>{
                let errors = this.state.errors;
                let stages={};
                errors[this.props.field_api_name] = '';
                stages[this.props.field_api_name] = ''; 
                stages['stage'] = this.props.stage; 
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });
        if(this.state.input[this.props.field_api_name]===false || this.state.input[this.props.field_api_name]===undefined){
            this.props.field_api_validations.map((src)=>{
                errors[this.props.field_api_name] = src.flxError_Message__c;
                stages[this.props.field_api_name] = src.flxError_Message__c; 
                stages['stage'] = this.props.stage; 
                this.props.appvalidations(stages);
                this.setState({
                  errors
                });

                return false;

            })
           
        }

      }

    
    render() { 
        return (
            <></>
          );
    }
}


export const mapStateToProps = (state) => {
    return {
        applicationValidation:state.dataStore.applicationValidation,
        showAppValidations:state.dataStore.showAppValidations,
        prepopulateStatus:state.dataStore.prepopulateStatus,
        questionValueSave:state.dataStore.questionValueSave,
        saveApplicationData:state.dataStore.saveApplicationData,
    };
   };
  
  
  export  const mapDispatchToProps = (dispatch) => {
    return {
        appvalidations:(validations)=>{
            return dispatch({
                type: 'APP_VALIDATIONS',
                value: validations
            });
        },
        questionSave:(data)=>{
          return dispatch({
            type: 'QUESTIONSAVE',
            value: data
        });
        },
        saveApplicationDatas:(data)=>{
          return dispatch({
            type: 'SAVEDATA',
            value: data
        });
        },
        saveDocumentAgreeLabel:(data)=>{
          return dispatch({
            type: 'DOCUMENT_AGREE_LABEL',
            value: data
          });
        },
        saveContactAgreeLabel:(data)=>{
          return dispatch({
            type: 'CONTACT_AGREE_LABEL',
            value: data
          });
        },
        saveAgreeLabel:(data)=>{
          return dispatch({
            type: 'AGREE_LABEL',
            value: data
          });
        },
        
    }
   }
  
  export default connect(mapStateToProps,mapDispatchToProps)(CheckBox)