import React from "react";
 import QRCode from "qrcode.react";
import {handoffToken} from '../../action/application/handoffToken'
import {getStatus} from '../../action/application/getStatus'
import { Redirect, withRouter } from 'react-router';
import { analyticsClickEvent } from "../../action/firebaseAnalytics/firebaseAnalytics";
import Cookies from 'universal-cookie';
import parse from 'html-react-parser';
import $ from "jquery";
import { frontEndLogs } from "../../action/logs/frontEndLogs";
import { GTMApplicationSubmitStatus } from "../../utils/GtmDatalayer";
import { PropTypes } from 'prop-types';

export class QrCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qrValue: '',
            generateqr: false,
            sendapi: false,
            sessionid:localStorage.getItem('sessionId'),
            isshowdesktop:true,
            token:'',
            tokenValue:'',
            redirectURL:'',
            qrScanned:false,
            countStatus:0,
            greater:false,
            qrIn:false,
            reGenerateQRCode:false,
            RedirectToHome:false,
            applicationStatusHeading:'',
            applicationStatusBody:'',
            customPath: '/home',
            popupButtonLabel: '',
            redirectApproveURL: '',
            isGTMDataSent: false,
            referMessageTitle:''
        };
        this.loadData=this.loadData.bind(this);
        this.processloadData=this.processloadData.bind(this);
        this.loadDataCompletedData=this.loadDataCompletedData.bind(this);
        this.intervalID ='';
        this.checkIntervalID ='';
    }

     componentDidMount() {
        this.props.handleLoader(true);
        window.dataLayer = window.dataLayer || {};

        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        this.generateToken().catch(error => { console.log(error)});
        let applicationId = this.getApplicationIDInstorage();
        if(applicationId !== '' && applicationId != null && applicationId !== undefined){
          window.dataLayer.push({
              "event" : "metadata",
              "applicationID" : applicationId
          });
        }
        frontEndLogs('QR is shown in the QR component- '+applicationId,false,200,'componentDidMount');
    }

    callevent=()=>{
        console.log('qroud',this.state.greater);
        if(this.state.qrIn===false){
            this.loadData().catch(error => { console.log(error)});
            this.processloadData().catch(error => { console.log(error)});
            localStorage.setItem('pageLanded','');
            this.intervalID =setInterval(this.loadData, 30000);
            this.setState({qrIn:true})
        }
    }
    
      async loadData() {
          if(this.state.qrScanned===true){
            this.setState({greater:true});
            clearInterval(this.intervalID);
          }
          if(this.state.greater===false){
         try {
          let obj = '';
          if (localStorage.getItem('fromWhere') === 'skip') {
            obj={
              "email":localStorage.getItem('flxPersonEmail__c'),
              "application_id":await this.getApplicationIDInstorage()
          }
          }else{
            obj={
                "email":localStorage.getItem('email'),
                "application_id":await this.getApplicationIDInstorage()
            }
          }
          this.isNullCheckForGetStatusResult(obj)
          .catch(error => { console.log(error)});
        } catch (e) {
            console.log(e);
        }
        }else{
          clearInterval(this.intervalID);
        }
      }
      
      async processloadData() {
        if(this.state.miteckCompleted===true){
          clearInterval(this.processedDocument);
        }
       try {
         if(localStorage.getItem('processedPageLanded')==='yes'){
          this.setState({miteckCompleted:true})
         }
      } catch (e) {
          console.log(e);
      }
    }

    displayButtonLabelPopup = () => {
      if(this.state.popupButtonLabel !== '' && this.state.popupButtonLabel !== undefined){
        const buttonElement = document.querySelector('#mitekSucesssModal > div > div > div.modal-footer.mb-4 > button')
        buttonElement.style.width = "250px";

        return this.state.popupButtonLabel;
      }
      else if (localStorage.getItem('fromWhere') === 'apply_now'){
        return `Return Login`
      }
      else {
        return `Return Home`
      }
    }

    isNullCheckForGetStatusResult = async(requestObject) => {
      let applicationId = await this.getApplicationIDInstorage();
      if((applicationId !== null || applicationId !== undefined) && localStorage.getItem('email') !== null && 
      this.state.tokenValue !== ''){
          const result= await getStatus(requestObject);
          if(result.data.data.hummuk_mitek_status_track_application_status){
           if(result.data.data.hummuk_mitek_status_track_application_status==="1"){
              this.checkIntervalID =setInterval(this.loadDataCompletedData, 30000);
              this.setState({qrScanned:true})
            }
          }
      }
    }

    checkIfButtonLabelAndRedirectUrLExistsForQRcode = (dataResponse) => {

      if(dataResponse.buttonLabel !== '' && dataResponse.buttonLabel !== null && dataResponse.buttonLabel !== undefined) {
        this.setState({ popupButtonLabel: dataResponse.buttonLabel});
      }
  
      if(dataResponse.redirectUrl !== '' && dataResponse.redirectUrl !== null && dataResponse.redirectUrl !== undefined) {
        this.setState({ redirectApproveURL: dataResponse.redirectUrl});
      }
    }

    redirectToURLIfExists = () => {
      if(this.state.redirectApproveURL !== '' && this.state.redirectApproveURL !== null && this.state.redirectApproveURL !== undefined) {
        window.location.href = this.state.redirectApproveURL;
      }
    }

    GTMApplicationStatus = (applicationStatus) => {
      if(!this.state.isGTMDataSent) {
        switch(applicationStatus){
          case "APPROVED":
            localStorage.getItem('fromWhere') === 'apply_now'
            ? GTMApplicationSubmitStatus('Step 4','server', 4, 4, "4 : 4", "[Approved]")
            : GTMApplicationSubmitStatus('Step 3','server', 3, 3, "3 : 3", "[Approved]");
            analyticsClickEvent('application_approved', '');
            break;
          case "REFERRED":
            localStorage.getItem('fromWhere') === 'apply_now'
            ? GTMApplicationSubmitStatus('Step 4','server', 4, 4, "4 : 4", "[Refer]")
            : GTMApplicationSubmitStatus('Step 3','server', 3, 3, "3 : 3", "[Refer]");
            analyticsClickEvent('application_referred');
            break;
          case "DECLINED":
            localStorage.getItem('fromWhere') === 'apply_now'
            ? GTMApplicationSubmitStatus('Step 4','server', 4, 4, "4 : 4", "[Decline]")
            : GTMApplicationSubmitStatus('Step 3','server', 3, 3, "3 : 3", "[Decline]")
            break;
          }
          this.setState({isGTMDataSent: true})
      }
    }

      displayMitekResponseForUser = (verifyResultData) => {
        if(verifyResultData.hummuk_mitek_status_track_application_status){
          if(verifyResultData.hummuk_mitek_status_track_application_status==="2"){
            if(verifyResultData.application_current_application_status==="approved"){
                this.GTMApplicationStatus('APPROVED');
                this.setState({applicationStatusHeading:'APPROVED'})
                this.setState({applicationStatusBody: verifyResultData.hummuk_mitek_status_track_message});
                
                this.checkIfButtonLabelAndRedirectUrLExistsForQRcode(verifyResultData);
            }

            if(verifyResultData.application_current_application_status==="canceled" || verifyResultData.application_current_application_status==="pending"){
                this.GTMApplicationStatus('DECLINED')
                this.setState({applicationStatusHeading:'MESSAGE'})
                let resultMessage = verifyResultData.hummuk_mitek_status_track_message;
                if (this.extractEmails(resultMessage) != null) {
                  let newtext = '';
                  let value = this.extractEmails(resultMessage);
                  value.map((source) => {
                    let anchorTag = "<a target='_blank' href='https://mail.google.com/mail/u/0/?fs=1&to=" + source + "&su=Enquiry&tf=cm'>" + source + "</a>"
                    newtext = resultMessage.replace(source, anchorTag);
                    resultMessage = newtext;
    
                  })
                }
                this.setState({applicationStatusBody: resultMessage})
                this.checkIfButtonLabelAndRedirectUrLExistsForQRcode(verifyResultData);
            }

            window.$('#mitekSucesssModal').modal('show');

            setTimeout(() => {
              this.redirectToURLIfExists();
            },10000);
        }
        else if(verifyResultData.application_current_application_status==="REFER"){
          this.GTMApplicationStatus('REFERRED');
          this.setState({referMessageTitle:verifyResultData.message_title,applicationStatusHeading:'REFERRED'})
          this.setState({applicationStatusBody: verifyResultData.hummuk_mitek_status_track_message})
          this.checkIfButtonLabelAndRedirectUrLExistsForQRcode(verifyResultData);

          window.$('#mitekSucesssModal').modal('show');
          setTimeout(() => {
            this.redirectToURLIfExists();
          },10000);

          }
        }
      }

      async loadDataCompletedData(){
        console.log('loadDataCompletedData');
        let applicationId = await this.getApplicationIDInstorage();
        if(this.state.RedirectToHome===false && (applicationId !== null || applicationId !== undefined)
        && localStorage.getItem('email') !== null && this.state.tokenValue !== ''){
          const obj={
              "email":localStorage.getItem('email'),
              "application_id":applicationId
          }
  
          const result=await getStatus(obj);      
          if(result.data.statusCode === 400 && result.data.message === "Invalid Session"){
            const cookie = new Cookies();
            // Remove cookie for Retailer Id once the user session is expired
            cookie.remove('retailerId', { path: '/' });

            localStorage.clear();
            this.props.history.push('/login')
            window.location.reload(true);
          }
        if(result.data.statusCode ===200 && result.data.data !== undefined){
          this.displayMitekResponseForUser(result.data.data);
        }
              
        }else{
          clearInterval(this.checkIntervalID); 
        }
      
    }

    extractEmails = (text) => {
      return text.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
    }

    generateToken=async()=>{
      let obj = '';
      if (localStorage.getItem('fromWhere') === 'skip') {
        obj={
          "email":localStorage.getItem('flxPersonEmail__c'),
          "application_id":await this.getApplicationIDInstorage()
      }
      }else{
        obj={
            "email":localStorage.getItem('email'),
            "application_id":await this.getApplicationIDInstorage()
        }
      }

        const result=await handoffToken(obj);
        if(result.status == 200 && result.data.data.token !== undefined && result.data.data.token !== ''){
          await frontEndLogs('Mitek token is received from API - '+await this.getApplicationIDInstorage(),false,200,'get_web_token');
          this.props.handleLoader(false);
          let token = result.data.data.token;
          this.setState({tokenValue:token});
          let redirectURL=window.location.origin.toString()+'/mitek-humm/'+this.state.tokenValue;
          this.setState({redirectURL:redirectURL})

          if(this.state.greater===false){
            this.callevent()
          }
        }
        else {
          this.props.handleLoader(false);
        }
    }
    
    resize() {

        if(window.innerWidth <= 680){
            console.log(window.innerWidth);
            this.setState({isshowdesktop: false});
        }
    }
    
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));

        clearInterval(this.intervalID);
        clearInterval(this.processedDocument);
        clearInterval(this.checkIntervalID);
    }

    regenerateQRcode(){
         analyticsClickEvent('regenerateQRCode','')
        this.setState({reGenerateQRCode: true});

        // Show the Regenerate QRcode button after the QR code scanner is displayed
        setTimeout(() => {
          this.setState({reGenerateQRCode: false});
        }, 6000);
    }

    returnHome=()=>{
         analyticsClickEvent('return_to_home','')
      window.$('#mitekSucesssModal').modal('hide');
      if(this.state.redirectApproveURL !== '' && this.state.redirectApproveURL !== null && this.state.redirectApproveURL !== undefined) {
        window.location.href = this.state.redirectApproveURL
        return false;
      }
      if(localStorage.getItem('fromWhere') === 'apply_now'){
        this.props.history.push("/login");
        window.location.reload(false)
      }

      this.setState({RedirectToHome:true})
    }

    getApplicationIDInstorage(){
      const cookie = new Cookies();
      return localStorage.getItem('applicationId') ? localStorage.getItem('applicationId') :cookie.get('applicationId')
    }

    render() {
        console.log(window.location.origin.toString()+'/humm-verfication/'+this.state.tokenValue);
        if(this.state.RedirectToHome){
            return <Redirect push to={this.state.customPath} />;
        }
        if(this.state.qrScanned===true){
            return (
                <div>
                    
                  <h4 class="application-qr-sub-title">Please complete the Document Capture on your mobile phone</h4>
                  {this.state.reGenerateQRCode === false &&
                  <div class="text-center">
                  <button type="button" id="regenerate-btn" className="btn btn-primary" onClick={() => this.regenerateQRcode()}>Regenerate QR Code </button> 
                  </div>
                }  
                 {this.state.reGenerateQRCode === true &&      
                  <div className="QR-align">
                                 <QRCode size={200} value={`${window.location.origin.toString()+'/humm-verfication/'+this.state.tokenValue}`} />

                </div>  
                
        }   
              <div className="modal fade model-error" id="mitekSucesssModal" tabIndex="-1" role="dialog" aria-labelledby="mitekSucesssModalModel" aria-hidden="true" data-backdrop="false">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><img src="images/close-modal.svg"
                                    alt="x" /></button>
                            </div>
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>
                                {/* <h5 className="modal-title " id="mitekSucesssModal">{this.state.applicationStatusHeading}</h5> */}
                                {this.state.referMessageTitle &&
                                <h5 className="modal-title message-title" id="mitekSucesssModal">{this.state.referMessageTitle}</h5>
                                }
                                <p className="text-center mt-4">{parse(this.state.applicationStatusBody)}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button class="btn btn-warning btn-outline" onClick={(e) => { this.returnHome(e) }}>
                                {this.displayButtonLabelPopup()}</button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            );
        }else{
            return (
              <>
              {this.state.tokenValue === '' &&

              <div class="text-center">
                  <button type="button" className="btn btn-primary" onClick={this.generateToken}>Generate QR Code </button> 
              </div>
              }
              {this.state.tokenValue !== '' &&
                <div className="QR-align">
                                 <QRCode size={200} value={`${window.location.origin.toString()+'/humm-verfication/'+this.state.tokenValue}`} />
                              <div className="modal fade model-error" id="mitekSucesssModal" tabIndex="-1" role="dialog" aria-labelledby="mitekSucesssModalModel" aria-hidden="true" data-backdrop="false">
                  <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal"><img src="images/close-modal.svg"
                                    alt="x" /></button>
                            </div>
                            <div className="modal-body">
                                <div className="logo">
                                    <img src="images/modal-logo.svg" alt="logo" className="img-fluid" />
                                </div>
                                {/* <h5 className="modal-title " id="mitekSucesssModal">{this.state.applicationStatusHeading}</h5> */}
                                <p className="text-center mt-4">{parse(this.state.applicationStatusBody)}</p>
                            </div>
                            <div className="modal-footer mb-4">
                                <button class="btn btn-warning btn-outline" onClick={(e) => { this.returnHome(e) }}>Return Home</button>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              }
              </>
                
            );
        }
        
    }
}


QrCode.propTypes = { 
  handleLoader: PropTypes.func,
  history: PropTypes.object,
}


export default withRouter(QrCode);